import React, { useState, useCallback } from 'react';
import SkillRating from '../SkillRating/SkillRating';
import { getSkills } from '../../hooks/helpers';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';

const SkillSelection = ({
  selectedSkills,
  setSelectedSkills,
  searchedSkills,
  setSearchedSkills,
  roleMin,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const skillCallBack = (data) => {
    if (data?.results) {
      setSearchedSkills(data);
      return;
    }
    setSearchedSkills({
      results: [],
      num_pages: 1,
    });
  };

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      getSkills(skillCallBack, 1, [], searchValue);
    }, 400),
    []
  );

  const handleInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    getSkills(skillCallBack, selected + 1, [], searchTerm);
  };

  const handleSkillToggle = (skill) => {
    setSelectedSkills((prevSelectedSkills) =>
      prevSelectedSkills.some((s) => s._source.id === skill._source.id)
        ? prevSelectedSkills.filter((s) => s._source.id !== skill._source.id)
        : [...prevSelectedSkills, skill]
    );
  };

  // Separate the selected and unselected skills
  const unselectedSearchedSkills = searchedSkills.results.filter(
    (skill) => !selectedSkills.some((s) => s._source.id === skill._source.id)
  );

  return (
    <div className="div-block-78 sp">
      <div className="div-block-84 sk">
        <div className="text-block-62 res">
          Add More Skills to Your Profile or Select New Ones
        </div>
        <div className="form-block welcome sk skills w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            method="get"
            onSubmit={(e) => e.preventDefault()}
            className="form welcome_f skills"
          >
            <div className="div-block div-block-custom">
              <img
                src="images/magnifying-glass_1magnifying-glass.png"
                loading="lazy"
                alt=""
                className="image-2"
              />
            </div>
            <input
              className="text-field np w-input"
              value={searchTerm}
              onChange={(e) => {
                handleInputChange(e);
              }}
              maxLength={256}
              name="email-6"
              data-name="Email 6"
              placeholder="Search for more skills you want to develop"
              type="text"
              id="email-6"
              required=""
            />
          </form>
        </div>
        <a href="#" className="link-8 hide">
          See suggested skills
        </a>
        <div className="sug_skills">
          <div className="tx_s">{selectedSkills.length} Selected Skills</div>
          <div className="div-block-83 sp">
            <div className="div-block-17">
              {selectedSkills.map((skill) => (
                <div
                  key={skill._source.id}
                  className="div-block-16 welcome selected"
                  onClick={() => handleSkillToggle(skill)}
                >
                  <img
                    src="/images/checked_1checked.png"
                    loading="lazy"
                    alt=""
                    className="image-48"
                  />
                  <div className="text-block-12 welcome selected">
                    {skill._source.name}
                  </div>
                </div>
              ))}
              {unselectedSearchedSkills.map((skill) => (
                <div
                  key={skill._source.id}
                  className="div-block-16 welcome"
                  onClick={() => handleSkillToggle(skill)}
                >
                  <div className="text-block-12 welcome">
                    {skill._source.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-layout-hflex flex-block-38">
            <div className="w-layout-hflex pagination-block">
              {searchedSkills?.num_pages > 1 && (
                <ReactPaginate
                  onPageChange={paginate}
                  pageCount={searchedSkills?.num_pages}
                  previousLabel={'<'}
                  nextLabel={'>'}
                  containerClassName={
                    'w-layout-hflex pagination-block-container'
                  }
                  pageLinkClassName={
                    'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                  }
                  previousLinkClassName={
                    'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                  }
                  nextLinkClassName={
                    'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                  }
                  activeLinkClassName={
                    'w-layout-hflex pagination-btn table-pagination-btn-active'
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedSkills.length > 0 && (
        <SkillRating
          selectedSkills={selectedSkills}
          roleMin={roleMin}
          handleSkillToggle={handleSkillToggle}
          setSelectedSkills={setSelectedSkills}
        />
      )}
    </div>
  );
};

export default SkillSelection;
