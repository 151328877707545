import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from 'react-router-dom';
import {
  ActivityView,
  ArticleView,
  BrowseView,
  CoursesView,
  CourseView,
  CreatePathwayView,
  CredentialsView,
  CredentialView,
  IndexView,
  PathwaysView,
  PathwayView,
  ProgramsView,
  ProgramView,
  PublicProfileView,
  RecommendedView,
  SkillsView,
} from './views';
import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';
import DashboardView from './views/DashboardView/DashboardView';
import ProfileView from './views/ProfileView';
import {
  Logout,
  SsoLogin,
  getUserDataFromCookie,
  CompleteLogin,
  sentryCreateBrowserRouter,
  getUserName,
  api,
} from '@iblai/ibl-web-react-common';
import CourseIframeCourseContentView from './views/content/CourseIframeCourseContentView/CourseIframeCourseContentView';
import CourseIframeCourseTabView from './views/content/CourseIframeCourseTabView/CourseIframeCourseTabView';
import { inIframe } from './utils/helpers';
import StaticClassView from './views/StaticClassView';
import { isStaticPagesEnabled } from './hooks/helpers';
import IntroductoryView from "./views/IntroductoryView/IntroductoryView";

const STATIC_PAGES_ENABLED = isStaticPagesEnabled();

const authCheckFn = (shouldBeAdmin, shouldBeLoggedIn, appData, setAppData) => {
  return async () => {
    if (
        shouldBeAdmin &&
        (!appData.hasOwnProperty('current_tenant') ||
            !appData.current_tenant.is_admin)
    ) {
      throw new Response('Not Admin', { status: 403 });
    }
    if (!shouldBeLoggedIn) {
      return [];
    }
    const userDataFromCookie = getUserDataFromCookie();
    if (
        shouldBeLoggedIn &&
        (!appData?.axd_token ||
            !appData?.dm_token ||
            !userDataFromCookie ||
            userDataFromCookie?.user_id !== appData.userData?.user_id)
    ) {
      if (!inIframe()) {
        throw new Response(JSON.stringify({ shouldBeLoggedIn }), {
          status: 401,
        });
      } else {
        return [];
      }
    }
    return new Promise((resolve) => {
      if (!Object.keys(appData.userMetaData).length) {
        api.ibledxusers.getUsersManageMetadata(
            { username: getUserName() },
            function (data) {
              setAppData({
                ...appData,
                userMetaData: { ...data },
                userMetaDataLoaded: true,
              });
              resolve([]);
            },
            () => {
              resolve([]);
            }
        );
      } else {
        resolve([]);
      }
    });
  };
};

const ErrorPage = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 />;
    } else if (error.status === 401) {
      if (JSON.parse(error.data)?.shouldBeLoggedIn) {
        localStorage.setItem('redirect-path', window.location.pathname);
        window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`;
      }
    }
  }
};

const CourseIframeCourseTabWrapper = () => {
  const params = useParams();
  let tab = params.tab;
  if (params.hasOwnProperty('*')) {
    tab += `/${params['*']}`;
  }

  return <CourseIframeCourseTabView tab={tab} key={tab} />;
};

const route = (appData, setAppData) => {
  const routes = [
    {
      path: '/',
      errorElement: <ErrorPage />,
      children: [
        ...(STATIC_PAGES_ENABLED
            ? [
              {
                path: '',
                element: <StaticClassView />,
                loader: authCheckFn(false, false, appData, setAppData),
              },
              {
                path: 'public',
                element: <StaticClassView />,
                loader: authCheckFn(false, false, appData, setAppData),
                children: [
                  {
                    path: ':publicPath',
                    element: <StaticClassView />,
                  },
                ],
              },
            ]
            : []),
        {
          path: '',
          element: <DashboardView />,
          loader: authCheckFn(false, true, appData, setAppData),
          children: [
            {
              path: `${STATIC_PAGES_ENABLED ? `${process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_DASHBOARD_PATH}` : ''}`,
              element: <IndexView />,
            },
            {
              path: 'profile',
              element: <ProfileView />,
              children: [
                {
                  path: 'activity',
                  element: <ActivityView />,
                },
                {
                  path: 'credentials',
                  element: <CredentialsView />,
                },
                {
                  path: ':credentials/:credentialID',
                  element: <CredentialView />,
                },
                {
                  path: 'pathways/:pathwayID/:username',
                  element: <PathwayView />,
                },
                {
                  path: 'pathways/new',
                  element: <CreatePathwayView />,
                },
                {
                  path: 'skills',
                  element: <SkillsView />,
                },
                {
                  path: 'pathways',
                  element: <PathwaysView />,
                },
                {
                  path: 'courses',
                  element: <CoursesView />,
                },
                {
                  path: 'programs',
                  element: <ProgramsView />,
                },
                {
                  path: 'public-profile',
                  element: <PublicProfileView />,
                },
              ],
            },
            {
              path: 'courses/:courseID',
              element: <CourseView />,
            },
            {
              path: 'programs/:programID',
              element: <ProgramView />,
            },
            {
              path: 'courses/:courseID',
              element: <ArticleView />,
              children: [
                {
                  path: 'course',
                  element: <CourseIframeCourseContentView />,
                },
                {
                  path: ':tab/*', // Matches any subpath not matched by above
                  element: <CourseIframeCourseTabWrapper />,
                },
              ],
            },
            {
              path: 'recommended',
              element: <RecommendedView />,
            },
            {
              path: 'discover',
              element: <BrowseView />,
            },
          ],
        },
        ...(process.env.REACT_APP_IBL_APP_ENABLE_START_ROLE === 'true'
            ? [
              {
                path: 'start',
                element: <IntroductoryView />,
              },
            ]
            : []),
        {
          path: 'certificates/:credentialID',
          element: <CredentialView />,
        },
        {
          path: 'login/complete',
          element: <CompleteLogin />,
        },
        {
          path: 'sso-login',
          element: <SsoLogin />,
          loader: authCheckFn(false, false, appData, setAppData),
        },
        {
          path: 'logout',
          element: (
              <Logout
                  redirectTo={
                    STATIC_PAGES_ENABLED
                        ? window.location.origin
                        : `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`
                  }
              />
          ),
          loader: authCheckFn(false, false, appData, setAppData),
        },
      ],
    },
  ];

  return sentryCreateBrowserRouter(routes);
};

export default route;
