import { Outlet } from 'react-router-dom';
import { ProfileMenu } from '@iblai/ibl-web-react-common';
import { inIframe } from '../utils/helpers';

let menu = [
  {
    link: '/profile/activity',
    name: 'Activity',
  },
  {
    link: '/profile/skills',
    name: 'Skills',
  },
  {
    link: '/profile/credentials',
    name: 'Credentials',
  },
  {
    link: '/profile/pathways',
    name: 'Pathways',
  },
  {
    link: '/profile/programs',
    name: 'Programs',
  },
  {
    link: '/profile/courses',
    name: 'Courses',
  },
  {
    link: '/profile/public-profile',
    name: 'Public Profile',
  },
];

if (process.env.REACT_APP_ACTIVE_PROFILE_TABS) {
  menu = menu.filter((menuItem) =>
    process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(',').includes(menuItem.name)
  );
}

const ProfileView = () => {
  return (
    <div className="panels" style={inIframe() ? { paddingTop: '8px' } : {}}>
      <div
        className="panels_cont"
        style={inIframe() ? { paddingTop: '0px', margin: 0 } : {}}
      >
        <div
          className="panel-profile panel"
          style={
            inIframe() ? { paddingTop: '0px', margin: 0, width: '100%' } : {}
          }
        >
          <div className="div-block-421">
            <div className="profile_menu">
              <ProfileMenu menu={menu}></ProfileMenu>

              <div className="prof_menu hidden" data-name="feed">
                Feed
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
