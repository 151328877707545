import React, { useEffect, useState, useCallback } from 'react';
import { getRoles, rolesSearchAPI } from '../../hooks/helpers';
import ReactPaginate from 'react-paginate';
import './RoleSelection.css';
import { isJSON } from '@iblai/ibl-web-react-common';
import { debounce } from 'lodash';

const RoleSelection = ({
  selectedRoles,
  setSelectedRoles,
  searchedRoles,
  setSearchedRoles,
  rolesLoaded,
  setRolesLoaded,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const roleCallBack = (data) => {
    if (data?.results) {
      setSearchedRoles(data);
      return;
    }
    setSelectedRoles({
      results: [],
      num_pages: 1,
    });
  };

  const getRoleDescription = (role) => {
    if (isJSON(role._source.data)) {
      return JSON.parse(role._source.data).description;
    }
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
    getRoles(roleCallBack, selected + 1, [], searchTerm);
  };

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      getRoles(roleCallBack, 1, [], searchValue);
    }, 400),
    []
  );

  const handleInputChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleRoleToggle = (role) => {
    setSelectedRoles((prevSelectedRoles) =>
      prevSelectedRoles.some((r) => r._source.role_id === role._source.role_id)
        ? prevSelectedRoles.filter(
            (r) => r._source.role_id !== role._source.role_id
          )
        : [...prevSelectedRoles, role]
    );
  };

  const mergeSelectedAndSearchedRoles = () => {
    const selectedRoleIds = selectedRoles.map((role) => role._source.role_id);
    const mergedRoles = [
      ...selectedRoles,
      ...searchedRoles.results.filter(
        (role) => !selectedRoleIds.includes(role._source.role_id)
      ),
    ];
    return mergedRoles;
  };

  const mergedRoles = mergeSelectedAndSearchedRoles();

  return (
    <div className="div-block-78">
      <div className="div-block-84 role">
        <div className="text-block-62">Select Your Role</div>
        <div className="form-block welcome w-form">
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            method="get"
            onSubmit={(e) => e.preventDefault()}
            className="form welcome_f"
          >
            <div className="div-block div-block-custom">
              <img
                src="/images/magnifying-glass_1magnifying-glass.png"
                loading="lazy"
                alt=""
                className="image-2"
              />
            </div>
            <input
              className="text-field np w-input"
              value={searchTerm}
              onChange={(e) => {
                handleInputChange(e);
              }}
              maxLength={256}
              name="email-6"
              data-name="Email 6"
              placeholder="Search for a Role"
              type="text"
              id="email-6"
              required=""
            />
          </form>
        </div>
        <div className="sug_roles">
          <div className="tx_s" style={{ marginBottom: '36px' }}>
            {selectedRoles.length} Selected Roles
          </div>
          <div className="div-block-232">
            {mergedRoles.map((role) => {
              const isSelected = selectedRoles.some(
                (r) => r._source.role_id === role._source.role_id
              );
              return (
                <div
                  key={role._source.role_id}
                  className={`role_div ${isSelected ? 'selected' : ''}`}
                  onClick={() => handleRoleToggle(role)}
                >
                  {isSelected && (
                    <img
                      src="/images/checked_1checked.png"
                      loading="lazy"
                      alt=""
                      className="image-48"
                    />
                  )}
                  <div className="text-block-145">{role._source.name}</div>
                  <div className="text-block-146">
                    {getRoleDescription(role)}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-layout-hflex pagination-block m-top">
            <div className="w-layout-hflex pagination-block-container">
              {searchedRoles.num_pages > 1 && (
                <ReactPaginate
                  onPageChange={paginate}
                  pageCount={searchedRoles.num_pages}
                  previousLabel={'<'}
                  nextLabel={'>'}
                  containerClassName={
                    'w-layout-hflex pagination-block-container'
                  }
                  pageLinkClassName={
                    'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                  }
                  previousLinkClassName={
                    'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                  }
                  nextLinkClassName={
                    'w-layout-hflex pagination-btn greyish-bg-in-darkmode'
                  }
                  activeLinkClassName={
                    'w-layout-hflex pagination-btn table-pagination-btn-active'
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleSelection;
