import { useEffect, useRef, useState } from 'react';
import { getRoles } from './helpers';

function useRole() {
  const [searchedRoles, setSearchedRoles] = useState({
    results: [],
    num_pages: 1,
  });
  const [rolesLoaded, setRolesLoaded] = useState(false);

  useState(false);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getRoles(
        (data) => {
          setSearchedRoles(data);
          setRolesLoaded(true);
        },
        1,
        [],
        ''
      );
      hasFetchedData.current = true;
    }
  }, []);

  return { searchedRoles, setSearchedRoles, rolesLoaded, setRolesLoaded };
}

export default useRole;
