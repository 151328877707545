import Searchbar from '../Searchbar/Searchbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './Header.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  getAnalyticsURL,
  getLoginURL,
  getTenant,
  isCustomMenusEnabled,
  isCustomSubMenusEnabled,
  isStaticPagesEnabled,
} from '../../hooks/helpers';
import {
  AppDataContext,
  GLOBAL_COLORS,
  IBLUserProfile,
  isJSON,
} from '@iblai/ibl-web-react-common';
import { HiOutlineBell } from 'react-icons/hi';
import $ from 'jquery';
import { useDebounce } from '../../hooks/useDebounce';
import TrialBanner from './TrialBanner';
import { useTranslation } from 'react-i18next';
import Logo from '../Logo/Logo';
import { AiOutlineClose } from 'react-icons/ai';
import useStartRole from '../../hooks/useStartRole';
import { FiMenu } from "react-icons/fi";


const STATIC_PAGES_ENABLED = isStaticPagesEnabled();
const CUSTOM_MENUS_ENABLED = isCustomMenusEnabled();
const CUSTOM_SUBMENUS_ENABLED = isCustomSubMenusEnabled();

const analyticsURL = getAnalyticsURL();
const loginURL = getLoginURL();
const Header = (props) => {
  const { appData, setAppData } = useContext(AppDataContext);
  const [customMenus, setCustomMenus] = useState([]);
  const { t } = useTranslation();
  const location = useLocation();
  const isProfileActive = location.pathname.startsWith('/profile');
  const [mobileSidebarOpened, setMobileSidebarOpened] = useState(false);
  const mobileContainerRef = useRef();
  // navigate to start screen if its enabled and the user has no desired skills
  useStartRole();

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');

    if (favicon) {
      favicon.href = process.env.REACT_APP_FAVICON_URL;
    } else {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = process.env.REACT_APP_IBL_DEFAULT_FAVICON_URL;
      document.head.appendChild(link);
    }
  }, []);

  const handleCustomMenu = () => {
    const subMenusList = [];
    const originURL = new URL(window.location.href).origin;

    if (CUSTOM_SUBMENUS_ENABLED) {
      const subMenuInString =
        process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_HTML_SUBMENUS;
      const firstLevelSubMenus = String(subMenuInString).split('|');
      if (firstLevelSubMenus.length > 0) {
        firstLevelSubMenus.forEach((menus) => {
          const menu = String(menus).split(':');
          subMenusList.push({
            label: menu[0],
            src: `${originURL}/${process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_IFRAME_SUBMENU_HTML_SUB_FOLDER_PATH}/${menu[1]}.html`,
          });
        });
      }
    }
    const menuInString = process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_MENUS;
    const firstLevelMenus = String(menuInString).split('|');
    if (firstLevelMenus.length > 0) {
      const menusList = [];
      firstLevelMenus.forEach((menus) => {
        const menu = String(menus).split(':');
        const submenu = subMenusList.find((el) => el.label === menu[0]);
        menusList.push({
          label: menu[0],
          link: menu[1],
          ...(submenu && Object.keys(submenu).length
            ? {
                submenuSrc: submenu.src,
              }
            : {}),
        });
      });
      setCustomMenus(menusList);
    }
  };

  const handleNotificationClick = () => {
    // $('.div-block-163 .notification-trigger').on('click', function () {
    //   $('.div-block-163 .div-block-164').show();
    // });
    // //HIDING DROPDOWN WHEN CLICKING ELSEWHERE
    // $(document).on('click', function (e) {
    //   if (
    //     $('.div-block-163 .div-block-164:visible') &&
    //     !$(e.target).closest('.div-block-163 .notification-trigger').length
    //   ) {
    //     $('.div-block-163 .div-block-164:visible').hide();
    //   }
    // });
    return;
  };

  const handleMobileTriggerClick = () => {
    $(document).on('click', function (e) {
      if (
        $('.ibl-mobile-menu-sidebar.open:visible').length &&
        !$(e.target).closest('.ibl-mobile-menu-sidebar-container').length
      ) {
        //$('.ibl-mobile-menu-sidebar').fadeOut('fast')
        $('.ibl-mobile-menu-sidebar').removeClass('open');
      }
    });
  };

  useEffect(() => {
    function handler(event) {
      if (!mobileContainerRef.current?.contains(event.target)) {
        setMobileSidebarOpened(false);
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, []);

  /*useDebounce(
    () => {
      handleMobileTriggerClick();
    },
    200,
    []
  );*/

  return (
    <>
      <div className="nav">
        <TrialBanner />
        <div className="p_menu">
          <Logo
            STATIC_PAGES_ENABLED={STATIC_PAGES_ENABLED}
            CUSTOM_MENUS_ENABLED={CUSTOM_MENUS_ENABLED}
            handleCustomMenu={handleCustomMenu}
          />
          <div className="div-block-231">
            {STATIC_PAGES_ENABLED &&
              CUSTOM_MENUS_ENABLED &&
              customMenus.map((menu, index) => (
                <React.Fragment key={index}>
                  <NavLink
                    className={({ isActive, isPending, isTransitioning }) =>
                      [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                    }
                    to={menu.link}
                  >
                    <>
                      <span>{menu.label}</span>
                      {!!menu?.submenuSrc && (
                        <div className="html-submenu">
                          <iframe src={menu.submenuSrc} />
                        </div>
                      )}
                    </>
                  </NavLink>
                </React.Fragment>
              ))}
            {!CUSTOM_MENUS_ENABLED && (
              <>
                <NavLink
                  className={({ isActive, isPending, isTransitioning }) =>
                    [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                  }
                  to="/"
                >
                  {t('header.home', { defaultValue: 'Home' })}
                </NavLink>
                <NavLink
                  className={({ isActive, isPending, isTransitioning }) =>
                    [isProfileActive ? 'active' : '', 'dash_nav_item'].join(' ')
                  }
                  to="/profile/activity"
                >
                  {t('header.profile', { defaultValue: 'Profile' })}
                </NavLink>
                <NavLink
                  className={({ isActive, isPending, isTransitioning }) =>
                    [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                  }
                  to={process.env.REACT_APP_RECOMMENDED_EXTERNAL_LINK ? process.env.REACT_APP_RECOMMENDED_EXTERNAL_LINK : "/recommended"}
                >
                  {t('header.recommended', { defaultValue: process.env.REACT_APP_REPLACE_RECOMMENDED_WITH_SOCIAL && process.env.REACT_APP_REPLACE_RECOMMENDED_WITH_SOCIAL === 'true' ? 'Social' : 'Recommended' })}
                </NavLink>
                <NavLink
                  className={({ isActive, isPending, isTransitioning }) =>
                    [isActive ? 'active' : '', 'dash_nav_item'].join(' ')
                  }
                  to={
                    process.env.REACT_APP_DISCOVER_EXTERNAL_LINK
                      ? process.env.REACT_APP_DISCOVER_EXTERNAL_LINK
                      : '/discover'
                  }
                >
                  {t('header.discover', { defaultValue: 'Discover' })}
                </NavLink>
              </>
            )}
          </div>
          <div className="prof_menu hidden" data-name="feed">
            Feed
          </div>
          <Searchbar isMobileForm={true}></Searchbar>
          <div className="nav-right">
            <div className="div-block-170">
              <Searchbar></Searchbar>
              { process.env.REACT_APP_TOP_RIGHT_LOGO_TEXT &&
              <Link
                  style={{textDecoration: 'none', color: 'var(--font)', fontSize: '1.4em', marginLeft: '12px'}}
                  to={process.env.REACT_APP_TOP_RIGHT_LOGO_URL ?? '/'}
                >
                  {process.env.REACT_APP_TOP_RIGHT_LOGO_TEXT}
              </Link>
              }
              {isJSON(appData?.current_tenant) &&
                JSON?.parse(appData?.current_tenant)?.is_admin && (
                  <Link
                    className="link-block-32 ai-analytics w-inline-block"
                    to={analyticsURL}
                  >
                    <div className="text-block-309">
                      {t('header.ai_analytics', {
                        defaultValue: 'AI Analytics',
                      })}
                    </div>
                  </Link>
                )}
              {!appData?.userData && (
                <Link
                  className="link-block-32 ai-analytics w-inline-block"
                  to={loginURL}
                >
                  <div className="text-block-309">
                    {t('header.login', { defaultValue: 'Log in' })}
                  </div>
                </Link>
              )}
              {appData?.userData && (
                <>
                  <div className="div-block-163">
                    <HiOutlineBell
                      color={GLOBAL_COLORS.primary}
                      className={'image-3 notification-trigger'}
                    />
                    <img
                      alt=""
                      className="image-3h"
                      loading="lazy"
                      src="images/629f2ca4ae5641d483147dd9_bell.png"
                    />
                    <div className="div-block-164">
                      <div className="div-block-165">
                        <div className="text-block-104">
                          {t('header.notifications', {
                            defaultValue: 'Notifications',
                          })}
                        </div>
                      </div>
                      <div className="div-block-166 empty-not">
                        <div className="text-block-105">
                          {t('header.no_new_notifications', {
                            defaultValue: 'There are no new notifications',
                          })}
                        </div>
                      </div>
                      <div className="div-block-166 pop-not">
                        <div className="div-block-635">
                          <img
                            src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bab26_cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1%20(1).jpeg"
                            loading="lazy"
                            alt=""
                            className="not-img"
                          />
                          <div className="div-block-636">
                            <div className="text-block-373">
                              Your subscription for{' '}
                              <a href="#" className="not-link">
                                <span className="product">Mentor</span>
                              </a>{' '}
                              has been renewed
                            </div>
                            <div className="text-block-374">6 hours ago</div>
                          </div>
                        </div>
                        <div className="div-block-635">
                          <img
                            src="https://assets-global.website-files.com/6596e21ac435cac3c66baad8/6596e21ac435cac3c66bab26_cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1%20(1).jpeg"
                            loading="lazy"
                            alt=""
                            className="not-img"
                          />
                          <div className="div-block-636">
                            <div className="text-block-373">
                              Your subscription for{' '}
                              <a href="#" className="not-link">
                                <span className="product">Mentor</span>
                              </a>{' '}
                              has started
                            </div>
                            <div className="text-block-374">30 days ago</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <IBLUserProfile
                    style={{ marginLeft: '20px' }}
                    showProfileMenu={(process.env.REACT_APP_ACTIVE_PROFILE_TABS && process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(",").includes('Public Profile')) || !process.env.REACT_APP_ACTIVE_PROFILE_TABS}
                    color={process.env.REACT_APP_FONT_COLOR}
                  />
                  {/*<Headerprofilemenue />*/}
                </>
              )}
            </div>
            <div className="image-106 hide">
              <img
                alt=""
                className="sm"
                loading="lazy"
                src="images/ibl-logo-animated-2.gif"
              />
            </div>
          </div>
          <div
            className="mobile_nav_trigger"
            onClick={() => {
              setTimeout(() => setMobileSidebarOpened(true), 100);
            }}
          >
            <FiMenu size={20} color={GLOBAL_COLORS.primary} />
          </div>
        </div>
        <div className="l-nav"></div>
        <div
          className={`ibl-mobile-menu-sidebar ${mobileSidebarOpened ? 'open' : ''}`}
        >
          <div
            ref={mobileContainerRef}
            className="w-layout-vflex ibl-mobile-menu-sidebar-container"
          >
            <div className="w-layout-hflex flex-block-40">
              <div className="w-layout-hflex flex-block-43">
                {/*<div react-component="headerAddContentBtn" className="div-block-163 pl_s">
                  <div className="text-block-389 image-3 plus_img"></div>
                  <img src="images/629f2ca4ae5641d483147dd9_bell.png" loading="lazy" alt="" className="image-3h" />
                  <div react-component="addContentBlock" className="div-block-164 plus-pop">
                    <div className="div-block-165 nl">
                      <div className="text-block-105">ADD CONTENT TO YOUR PROFILE</div>
                    </div>
                    <div className="box-h">
                      <div prop-event-value-onclick="handleHeaderContentBoxClick" react-component="contentBox"
                           prop-events-names="onClick" className="c-box"><img src="images/docs-copy.png" loading="lazy"
                                                                              sizes="100vw"
                                                                              srcSet="images/docs-copy-p-500.png 500w, images/docs-copy.png 512w"
                                                                              alt="" className="image-128" />
                        <div prop-data-value-title="Article" prop-data-names="title"
                             className="text-block-219">Article
                        </div>
                      </div>
                      <div prop-event-value-onclick="handleHeaderContentBoxClick" react-component="contentBox"
                           prop-events-names="onClick" className="c-box"><img src="images/docs-copy.png" loading="lazy"
                                                                              sizes="100vw"
                                                                              srcSet="images/docs-copy-p-500.png 500w, images/docs-copy.png 512w"
                                                                              alt="" className="image-128" />
                        <div prop-data-value-title="Article" prop-data-names="title" className="text-block-219">Video
                        </div>
                      </div>
                      <div prop-event-value-onclick="handleHeaderContentBoxClick" react-component="contentBox"
                           prop-events-names="onClick" className="c-box"><img src="images/docs-copy.png" loading="lazy"
                                                                              sizes="100vw"
                                                                              srcSet="images/docs-copy-p-500.png 500w, images/docs-copy.png 512w"
                                                                              alt="" className="image-128" />
                        <div prop-data-value-title="Article" prop-data-names="title"
                             className="text-block-219">Podcast
                        </div>
                      </div>
                      <div prop-event-value-onclick="handleHeaderContentBoxClick" react-component="contentBox"
                           prop-events-names="onClick" className="c-box"><img src="images/docs-copy.png" loading="lazy"
                                                                              sizes="100vw"
                                                                              srcSet="images/docs-copy-p-500.png 500w, images/docs-copy.png 512w"
                                                                              alt="" className="image-128" />
                        <div prop-data-value-title="Article" prop-data-names="title" className="text-block-219">Book
                        </div>
                      </div>
                      <div prop-event-value-onclick="handleHeaderContentBoxClick" react-component="contentBox"
                           prop-events-names="onClick" className="c-box"><img src="images/docs-copy.png" loading="lazy"
                                                                              sizes="100vw"
                                                                              srcSet="images/docs-copy-p-500.png 500w, images/docs-copy.png 512w"
                                                                              alt="" className="image-128" />
                        <div prop-data-value-title="Article" prop-data-names="title" className="text-block-219">Course
                        </div>
                      </div>
                      <div prop-event-value-onclick="handleHeaderContentBoxClick" react-component="contentBox"
                           prop-events-names="onClick" className="c-box"><img src="images/docs-copy.png" loading="lazy"
                                                                              sizes="100vw"
                                                                              srcSet="images/docs-copy-p-500.png 500w, images/docs-copy.png 512w"
                                                                              alt="" className="image-128" />
                        <div prop-data-value-title="Article" prop-data-names="title"
                             className="text-block-219">Program
                        </div>
                      </div>
                      <div prop-event-value-onclick="handleHeaderContentBoxClick" react-component="contentBox"
                           prop-events-names="onClick" className="c-box"><img src="images/docs-copy.png" loading="lazy"
                                                                              sizes="100vw"
                                                                              srcSet="images/docs-copy-p-500.png 500w, images/docs-copy.png 512w"
                                                                              alt="" className="image-128" />
                        <div prop-data-value-title="Article" prop-data-names="title"
                             className="text-block-219">Assesment
                        </div>
                      </div>
                      <div prop-event-value-onclick="handleHeaderContentBoxClick" react-component="contentBox"
                           prop-events-names="onClick" className="c-box">
                        <div prop-data-value-title="Article" prop-data-names="title" className="text-block-219">Create
                          Pathway
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
                <div className="div-block-163">
                  <HiOutlineBell
                    color={GLOBAL_COLORS.primary}
                    className={'image-3 notification-trigger'}
                  />
                  {/*<div className="div-block-164 skills-notification-box">
                    <div className="div-block-165">
                      <div className="text-block-104">Notifications</div>
                      <div className="w-layout-hflex flex-block-36">
                        <div className="text-block-397">Only show unread</div>
                        <div react-component="ToolsRowSwitch" className="html-embed-25 w-embed">
                          <label className="mini-switch">
                            <input type="checkbox" />
                            <span className="mini-switch-slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="div-block-166 empty-not inactive">
                      <div className="text-block-105">There are no new notifications</div>
                    </div>
                    <div className="div-block-166 pop-not">
                      <div className="single-notification-block"><img
                        src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg" loading="lazy"
                        alt="" className="not-img" />
                        <div className="notification-detail-block">
                          <div className="text-block-373">Your subscription for <a href="#" className="not-link"><span
                            className="product">Mentor</span></a> has been renewed
                          </div>
                          <div className="text-block-374">30 min</div>
                        </div>
                        <img src="images/x-12.svg" loading="lazy" alt="" className="notification-closer" />
                        <div className="w-layout-hflex unread-status-block"></div>
                      </div>
                      <div className="single-notification-block"><img
                        src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg" loading="lazy"
                        alt="" className="not-img" />
                        <div className="notification-detail-block">
                          <div className="text-block-373">You&#x27;ve earned +100 Skills Points on <a href="#"
                                                                                                      className="not-link"><span
                            className="product">Javascript</span></a>
                          </div>
                          <div className="text-block-374">2 hours ago</div>
                        </div>
                        <img src="images/x-12.svg" loading="lazy" alt="" className="notification-closer" />
                        <div className="w-layout-hflex unread-status-block"></div>
                      </div>
                      <div className="single-notification-block"><img
                        src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg" loading="lazy"
                        alt="" className="not-img" />
                        <div className="notification-detail-block">
                          <div className="text-block-373">You can now share your <a href="#" className="not-link"><span
                            className="product">Python Programming</span></a> credentials
                          </div>
                          <div className="text-block-374">4 hours ago</div>
                        </div>
                        <img src="images/x-12.svg" loading="lazy" alt="" className="notification-closer" />
                        <div className="w-layout-hflex unread-status-block"></div>
                      </div>
                      <div className="single-notification-block"><img
                        src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg" loading="lazy"
                        alt="" className="not-img" />
                        <div className="notification-detail-block">
                          <div className="text-block-373">You&#x27;ve earned +50 Skills Points on <a href="#"
                                                                                                     className="not-link"><span
                            className="product">Data Science</span></a>
                          </div>
                          <div className="text-block-374">2 days ago</div>
                        </div>
                        <img src="images/x-12.svg" loading="lazy" alt="" className="notification-closer" />
                        <div className="w-layout-hflex unread-status-block"></div>
                      </div>
                      <div className="single-notification-block"><img
                        src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg" loading="lazy"
                        alt="" className="not-img" />
                        <div className="notification-detail-block">
                          <div className="text-block-373">You&#x27;ve earned +150 Skills Points on <a href="#"
                                                                                                      className="not-link"><span
                            className="product">Machine Learning</span></a>
                          </div>
                          <div className="text-block-374">8 days ago</div>
                        </div>
                        <img src="images/x-12.svg" loading="lazy" alt="" className="notification-closer" />
                        <div className="w-layout-hflex unread-status-block"></div>
                      </div>
                      <div className="single-notification-block"><img
                        src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg" loading="lazy"
                        alt="" className="not-img" />
                        <div className="notification-detail-block">
                          <div className="text-block-373">New free course suggestion : <a href="#" className="not-link"><span
                            className="product">React for Begineers</span></a>
                          </div>
                          <div className="text-block-374">14 days ago</div>
                        </div>
                        <img src="images/x-12.svg" loading="lazy" alt="" className="notification-closer" />
                        <div className="w-layout-hflex unread-status-block"></div>
                      </div>
                      <div className="single-notification-block"><img
                        src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg" loading="lazy"
                        alt="" className="not-img" />
                        <div className="notification-detail-block">
                          <div className="text-block-373">Congratulations ! You&#x27;ve reached 100% Progress Score
                            on <a href="#" className="not-link"><span className="product">Data Science</span></a>
                          </div>
                          <div className="text-block-374">20 days ago</div>
                        </div>
                        <img src="images/x-12.svg" loading="lazy" alt="" className="notification-closer" />
                        <div className="w-layout-hflex unread-status-block"></div>
                      </div>
                      <div className="single-notification-block read"><img
                        src="images/cropped-5ff88d1d053bdb0e43bdcc0c_beautiful-1274361_1280-1-1.jpeg" loading="lazy"
                        alt="" className="not-img" />
                        <div className="notification-detail-block">
                          <div className="text-block-373">Your subscription for <a href="#" className="not-link"><span
                            className="product">Mentor</span></a> has started
                          </div>
                          <div className="text-block-374">30 days ago</div>
                        </div>
                        <img src="images/x-12.svg" loading="lazy" alt="" className="notification-closer" />
                        <div className="w-layout-hflex unread-status-block hidden"></div>
                      </div>
                    </div>
                    <div className="w-layout-hflex flex-block-37">
                      <a href="#" className="notification-mark-as-read-btn w-inline-block"><img
                        src="images/check-check.svg" loading="lazy" alt="" className="image-228" />
                        <h5 className="heading-11">Mark all as read</h5>
                      </a>
                      <div data-hover="false" data-delay="0"
                           className="skills-notification-date-filter-dropdown-block w-dropdown">
                        <div className="skills-notification-date-filter-dropdown-toggle w-dropdown-toggle"><img
                          src="images/calendar-days.svg" loading="lazy" alt="" className="image-229" />
                          <div className="skills-notification-date-filter-dropdown-icon w-icon-dropdown-toggle"></div>
                          <div className="text-block-399">Filter</div>
                        </div>
                        <nav className="skills-notification-date-filter-dropdown-list w-dropdown-list">
                          <div className="skills-notification-date-filter-form-block w-form">
                            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get"
                                  className="skills-notification-date-filter-form"
                                  data-wf-page-id="6596e21ac435cac3c66baad7"
                                  data-wf-element-id="fbcef20d-a1e8-570a-286b-1f685d33f0cc"><label
                              className="skills-notification-date-filter-radio-field w-radio"><input type="radio"
                                                                                                     data-name="day"
                                                                                                     id="1" name="day"
                                                                                                     className="w-form-formradioinput w-radio-input"
                                                                                                     value="1" /><span
                              className="skills-notification-date-filter-radio-fabel w-form-label"
                              htmlFor="1">Last day</span></label><label
                              className="skills-notification-date-filter-radio-field w-radio"><input type="radio"
                                                                                                     data-name="day"
                                                                                                     id="7" name="day"
                                                                                                     className="w-form-formradioinput w-radio-input"
                                                                                                     value="7" /><span
                              className="skills-notification-date-filter-radio-fabel w-form-label" htmlFor="7">Last 7 days</span></label><label
                              className="skills-notification-date-filter-radio-field w-radio"><input type="radio"
                                                                                                     data-name="day"
                                                                                                     id="15" name="day"
                                                                                                     className="w-form-formradioinput w-radio-input"
                                                                                                     value="15" /><span
                              className="skills-notification-date-filter-radio-fabel w-form-label" htmlFor="15">Last 15 days</span></label><label
                              className="skills-notification-date-filter-radio-field w-radio"><input type="radio"
                                                                                                     data-name="day"
                                                                                                     id="30" name="day"
                                                                                                     className="w-form-formradioinput w-radio-input"
                                                                                                     value="30" /><span
                              className="skills-notification-date-filter-radio-fabel w-form-label" htmlFor="30">Last 30 days</span></label>
                            </form>
                            <div className="w-form-done">
                              <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                              <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>*/}
                </div>
              </div>
              <div
                onClick={() => {
                  setMobileSidebarOpened(false);
                }}
                className="w-layout-hflex ibl-mobile-menu-sidebar-closer"
              >
                <AiOutlineClose color={GLOBAL_COLORS.primary} size={25} />
              </div>
            </div>
            <div className="w-layout-hflex flex-block-42">
              <IBLUserProfile
                style={{ marginLeft: '20px' }}
                color={process.env.REACT_APP_FONT_COLOR}
                showProfileMenu={(process.env.REACT_APP_ACTIVE_PROFILE_TABS && process.env.REACT_APP_ACTIVE_PROFILE_TABS.split(",").includes('Public Profile')) || !process.env.REACT_APP_ACTIVE_PROFILE_TABS}
                className={'ibl-mobile-menu-sidebar-user-profile-dropdown'}
              />
            </div>
            <div className="w-layout-vflex flex-block-44">
              <div className="div-block-231 v-flex show-mobile">
                {STATIC_PAGES_ENABLED &&
                  CUSTOM_MENUS_ENABLED &&
                  customMenus.map((menu, index) => (
                    <React.Fragment key={index}>
                      <NavLink
                        className={({ isActive, isPending, isTransitioning }) =>
                          [
                            isActive ? 'active' : '',
                            'dash_nav_item no-margin',
                          ].join(' ')
                        }
                        to={menu.link}
                      >
                        <>
                          <span>{menu.label}</span>
                          {!!menu?.submenuSrc && (
                            <div className="html-submenu">
                              <iframe src={menu.submenuSrc} />
                            </div>
                          )}
                        </>
                      </NavLink>
                    </React.Fragment>
                  ))}
                {!CUSTOM_MENUS_ENABLED && (
                  <>
                    <NavLink
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isActive ? 'active' : '',
                          'dash_nav_item no-margin',
                        ].join(' ')
                      }
                      to="/"
                    >
                      {t('header.home', { defaultValue: 'Home' })}
                    </NavLink>
                    <NavLink
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isProfileActive ? 'active' : '',
                          'dash_nav_item no-margin',
                        ].join(' ')
                      }
                      to="/profile/activity"
                    >
                      {t('header.profile', { defaultValue: 'Profile' })}
                    </NavLink>
                    <NavLink
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isActive ? 'active' : '',
                          'dash_nav_item no-margin',
                        ].join(' ')
                      }
                      to="/recommended"
                    >
                      {t('header.recommended', { defaultValue: 'Recommended' })}
                    </NavLink>
                    <NavLink
                      className={({ isActive, isPending, isTransitioning }) =>
                        [
                          isActive ? 'active' : '',
                          'dash_nav_item no-margin',
                        ].join(' ')
                      }
                      to="/discover"
                    >
                      {t('header.discover', { defaultValue: 'Discover' })}
                    </NavLink>
                  </>
                )}
              </div>
              {isJSON(appData?.current_tenant) &&
                JSON?.parse(appData?.current_tenant)?.is_admin && (
                  <Link
                    className="link-block-32 ai-analytics no-margin w-inline-block"
                    to={analyticsURL}
                  >
                    <div className="text-block-309">
                      {t('header.ai_analytics', {
                        defaultValue: 'AI Analytics',
                      })}
                    </div>
                  </Link>
                )}
              {!appData?.userData && (
                <Link
                  className="link-block-32 ai-analytics no-margin w-inline-block"
                  to={loginURL}
                >
                  <div className="text-block-309">
                    {t('header.login', { defaultValue: 'Log in' })}
                  </div>
                </Link>
              )}
            </div>
            <div className="w-layout-hflex flex-block-45">
              <Logo
                STATIC_PAGES_ENABLED={STATIC_PAGES_ENABLED}
                CUSTOM_MENUS_ENABLED={CUSTOM_MENUS_ENABLED}
                handleCustomMenu={handleCustomMenu}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
