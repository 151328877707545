import { TrialTopHeader } from '@iblai/ibl-web-react-common';
import React from 'react';
import useFreeTrial from '../../../hooks/useFreeTrial';

export default function TrialBanner(){
  const {usage, handleUpgrade} = useFreeTrial()
  const tooltip = `You are on a free trial and you have ${usage?.count} questions left
            before you are prompted to make a payment.`;
  const pillDisplay = `Upgrade`;
  const description = `You are on a free trial. ${usage?.count} questions left`;

  return (
    <div>
      {
        usage &&
        <TrialTopHeader
          handleClick={handleUpgrade}
          tooltipText={tooltip}
          pillDisplayText={pillDisplay}
          descriptionText={description}
          spaUsesMentorSidebar={true}
          fixedClassNamesToMove=".af-view .nav, .right-content"
        />
      }

    </div>
  )
}