import { useEffect, useRef, useState } from 'react';
import { getRoles, getSkills } from './helpers';

function useSkill() {
  const [searchedSkills, setSearchedSkills] = useState({
    results: [],
    num_pages: 1,
  });
  const [skillsLoaded, setSkillsLoaded] = useState(false);

  useState(false);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      getSkills(
        (data) => {
          setSearchedSkills(data);
          setSkillsLoaded(true);
        },
        1,
        [],
        ''
      );
      hasFetchedData.current = true;
    }
  }, []);

  return { searchedSkills, setSearchedSkills, skillsLoaded, setSkillsLoaded };
}

export default useSkill;
